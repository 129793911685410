<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Template Laporan Lab'">
          <template v-slot:body v-if="dataLoaded">
            <Form
              :form="form"
              :route="'2-lab-templates/' + form.id"
              :isPreview="isPreview"
              @setPreview="setPreview"
              @refreshTable="refreshTable"
            />
          </template>
        </Card>
      </div>

      <transition name="fade-in-up">
        <div class="col-md-12" v-if="isPreview">
          <Card v-bind:title="''">
            <template v-slot:body v-if="dataLoaded">
              <!-- HEADER::START -->
              <div class="row justify-content-center">
                <div class="col-6 text-center">
                  <img
                    :src="config.brand_logo"
                    style="max-width: 250px"
                    alt="LOGO KLINIK"
                  />
                  <h2>{{ config.clinic_name }}</h2>
                  <p>{{ config.clinic_address }}</p>
                </div>
              </div>
              <hr style="border: 1px solid" class="mt-6" />
              <div class="d-flex justify-content-center">
                <h3>HASIL LABORATORIUM</h3>
              </div>
              <!-- HEADER::END -->
              <!-- CONTENT -->
              <div class="row mt-5">
                <div class="col-6">
                  <table border="0">
                    <tr>
                      <td class="pr-6">Dokter</td>
                      <td class="pr-2">:</td>
                      <td>{{ form.reference }}</td>
                    </tr>
                    <tr>
                      <td>Alamat</td>
                      <td>:</td>
                      <td>{{ form.reference_address }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row my-4">
                <div class="col-6">
                  <table border="0">
                    <tr>
                      <td>ID Pasien</td>
                      <td>:</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="pr-3">Nama Pasien</td>
                      <td class="pr-2">:</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Alamat</td>
                      <td>:</td>
                      <td></td>
                    </tr>
                  </table>
                </div>
                <div class="col-6">
                  <table border="0">
                    <tr>
                      <td>Jenis Kelamin</td>
                      <td>:</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="pr-3">Tempat, Tanggal Lahir</td>
                      <td class="pr-2">:</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Telepon</td>
                      <td>:</td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="row mt-6">
                <div class="col">
                  <p>{{ form.opening_sentences }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <table class="table">
                    <tr>
                      <th>No</th>
                      <th>Jenis Pemeriksaan</th>
                      <th>Hasil</th>
                      <th>Nilai Normal</th>
                      <th>Catatan</th>
                    </tr>
                    <tr class="d-none">
                      <td>Lebih besar {{ form }}</td>
                      <td>Lebih besar {{ form.selected_fields.length }}</td>
                      <td>Lebih besar {{ form.selected_fields }}</td>
                    </tr>
                    <template v-if="form.selected_fields.length > 0">
                      <tr v-for="(item, i) in form.selected_fields" :key="i">
                        <td>
                          {{ i + 1 }} <span class="d-none">{{ form }}</span>
                        </td>
                        <td>{{ item.field_show_name }}</td>
                        <td>{{ item.result }}</td>
                        <td>
                          <span class="d-block"
                            >Laki-laki :
                            {{
                              item.start_range_male +
                              " - " +
                              item.end_range_male +
                              " " +
                              item.uom
                            }}</span
                          >
                          <span class="d-block"
                            >Perempuan :
                            {{
                              item.start_range_female +
                              " - " +
                              item.end_range_female +
                              " " +
                              item.uom
                            }}</span
                          >
                        </td>
                        <td></td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p>{{ form.closing_sentences }}</p>
                </div>
              </div>
              <div class="d-flex justify-content-start">
                <span>Waktu Pengambilan: </span>
              </div>
              <div class="d-flex justify-content-between">
                <div
                  class="
                    d-flex
                    flex-column
                    mt-5
                    justify-content-center
                    col-2
                    text-center
                    px-0
                  "
                  style="min-height: 150px"
                >
                  <div>
                    <span>Diotorisasi Oleh: </span>
                  </div>
                  <div class="mt-auto">
                    <span class="d-block" style="white-space: nowrap"
                      ><u>{{ form.authorizer_name }}</u></span
                    >
                    <span class="d-block">{{
                      form.authorizer_position
                    }}</span>
                    <!-- <hr class="mt-auto" style="border: 1px solid; width: 126px" /> -->
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-auto">
                  <div class="col-5">
                    <em
                      >Tanda * dan angka dicetak tebal menunjukkan nilai diatas
                      atau dibawah nilai normal</em
                    >
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/lab-procedures-2/FormTemplate.vue";
import module from "@/core/modules/CrudModule.js";
import { getConfiguration } from "@/core/services/jwt.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {
        selected_fields: [],
      },
      isPreview: false,
      config: getConfiguration(),
      // Other
      dataLoaded: false,
    };
  },

  methods: {
    async get() {
      this.form = await module.get(
        "lab-templates/" + this.$route.params.templateid
      );
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/masters/lab-templates/list");
      } else {
        this.form.field_ids = JSON.parse(this.form.field_ids);
        this.form["_method"] = "put";
        if (this.form.field_collection.length > 0) {
          this.form.selected_fields = this.form.field_collection;
          console.log("form sele", this.form.selected_fields);
        } else {
          this.form.selected_fields = [];
          console.log("form sele else", this.form.selected_fields);
        }
        this.dataLoaded = true;
      }
    },

    setPreview(value) {
      this.isPreview = value;
    },
    refreshTable(value) {
      console.log(
        "=============================================== receipt refreshTable"
      );
      // this.form.selected_fields.push(value);
      console.log("refresh table form", this.form);
      this.form.selected_fields.splice(this.form.selected_fields.length);
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab", route: "" },
      { title: "Daftar Harga Lab", route: "/lab-procedure-2/list" },
      { title: "Edit Template" },
    ]);
    // Get Data
    this.get();
  },
};
</script>